<template>
  <div>
    <head>
      <title>{{ pageTitle }}</title>
    </head>
    <div id="dropdown-port" name="dropdown" slim />
    <div class="flex flex-col">
      <div class="flex min-h-screen flex-col">
        <div class="md:flex">
          <div class="flex items-center justify-between bg-gray-900 px-6 py-4 md:w-56 md:flex-shrink-0">
            <inertia-link :href="route('dashboard')">
              <logo name="qualify" class="mt-2 block fill-white md:ml-5" width="132" height="34.67" />
            </inertia-link>
            <div
              class="hamburger hamburger--squeeze inline-block md:hidden"
              :class="{ 'is-active': open }"
              style="z-index: 99999"
              @click="open = !open"
            >
              <div class="hamburger-box">
                <div class="hamburger-inner" />
              </div>
            </div>
            <div class="absolute inset-x-0 top-0 bg-gray-800" :class="{ hidden: !open }" style="z-index: 99998">
              <div class="my-16 flex flex-col items-center">
                <main-menu />
              </div>
            </div>
          </div>
          <div
            class="hidden w-full items-center justify-between border-b bg-white p-4 text-sm md:flex md:px-12 md:py-0 md:text-base"
          >
            <dropdown
              v-if="
                ($page.props.auth.user.is_admin ||
                  $page.props.auth.user.is_support ||
                  $page.props.auth.user.is_super) &&
                $page.props.auth.user.is_worksite_user
              "
              class="mr-4 mt-1"
              placement="bottom-start"
              :before-toggle="loadTenants"
            >
              <div class="group flex cursor-pointer select-none items-center">
                <div class="mr-1 whitespace-nowrap text-gray-800 focus:text-blue-700 group-hover:text-blue-700">
                  {{ $page.props.app.tenant.name }}
                </div>
                <icon class="h-5 w-5 fill-gray-800 focus:fill-blue-700 group-hover:fill-blue-700" name="chevron-down" />
              </div>
              <template #dropdown>
                <div class="mt-2 max-h-xxs overflow-y-auto rounded bg-white py-2 text-sm shadow-lg">
                  <inertia-link
                    v-for="tenant in tenants"
                    :key="tenant.id"
                    class="block py-2 pl-4 pr-8 hover:bg-gray-200 hover:text-gray-700"
                    :href="route('tenants.login', tenant.id)"
                  >
                    {{ tenant.name }}
                  </inertia-link>
                </div>
              </template>
            </dropdown>
            <div v-else class="mr-4 mt-1">{{ $page.props.app.tenant.name }}</div>
            <dropdown class="mt-1" placement="bottom-end">
              <div class="group flex cursor-pointer select-none items-center">
                <div
                  class="relative mr-1 whitespace-nowrap text-gray-800 focus:text-blue-700 group-hover:text-blue-700"
                >
                  <span
                    v-if="$page.props.qualify.requestsCount > 0"
                    class="absolute -left-5 -top-0.5 flex h-5 w-5 items-center justify-center rounded-full bg-gray-100 text-sm"
                  >
                    <span>{{ $page.props.qualify.requestsCount }}</span>
                  </span>
                  <span class="mr-1">{{ $page.props.auth.user.first_name }}</span>
                  <span class="hidden md:inline">{{ $page.props.auth.user.last_name }}</span>
                </div>
                <icon class="h-5 w-5 fill-gray-800 focus:fill-blue-700 group-hover:fill-blue-700" name="chevron-down" />
              </div>
              <template #dropdown>
                <div class="mt-2 rounded bg-white py-2 text-sm shadow-lg">
                  <inertia-link
                    class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
                    :href="route('profile.edit')"
                  >
                    {{ $t('My Profile') }}
                  </inertia-link>
                  <inertia-link
                    v-if="$page.props.auth.user.is_super && $page.props.auth.user.is_worksite_user"
                    class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
                    :href="route('tenants')"
                  >
                    {{ $t('Manage Tenants') }}
                  </inertia-link>
                  <inertia-link
                    v-if="
                      ($page.props.auth.user.is_admin ||
                        $page.props.auth.user.is_support ||
                        $page.props.auth.user.is_super) &&
                      $page.props.auth.user.is_worksite_user
                    "
                    class="flex w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
                    :href="route('requests')"
                  >
                    {{ $t('Manage Requests') }}
                    <span class="ml-1 flex h-5 w-5 items-center justify-center rounded-full bg-gray-100 text-xs">
                      <span>{{ $page.props.qualify.requestsCount }}</span>
                    </span>
                  </inertia-link>
                  <inertia-link
                    v-if="
                      ($page.props.auth.user.is_admin ||
                        $page.props.auth.user.is_support ||
                        $page.props.auth.user.is_super) &&
                      $page.props.auth.user.is_worksite_user
                    "
                    class="flex w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
                    :href="route('proctoring-recordings')"
                  >
                    {{ $t('Manage Proctoring') }}
                    <span class="ml-1 flex h-5 w-5 items-center justify-center rounded-full bg-gray-100 text-xs">
                      <span>{{ $page.props.qualify.proctoringRecordingsCount }}</span>
                    </span>
                  </inertia-link>
                  <inertia-link
                    as="button"
                    class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
                    :href="route('logout')"
                    method="post"
                  >
                    {{ $t('Log out') }}
                  </inertia-link>
                </div>
              </template>
            </dropdown>
          </div>
        </div>
        <div class="flex flex-grow">
          <div class="ts-bg hidden w-56 flex-shrink-0 p-12 md:block">
            <main-menu />
          </div>
          <div class="w-full overflow-hidden px-4 py-8 md:p-12">
            <div
              v-if="$page.props.flash.success && showFlash"
              class="mb-8 flex max-w-lg items-center justify-between rounded bg-green-400"
            >
              <div class="flex items-center">
                <icon name="checkmark" class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" />
                <div class="py-4 text-sm font-medium text-white">{{ $page.props.flash.success }}</div>
              </div>
              <button type="button" class="group mr-2 p-2" @click="showFlash = false">
                <icon class="block h-3 w-3 fill-green-300 group-hover:fill-green-100" name="close" />
              </button>
            </div>
            <div
              v-if="$page.props.flash.error && showFlash"
              class="mb-8 flex max-w-lg items-center justify-between rounded bg-red-400"
            >
              <div class="flex items-center">
                <icon name="close-outline" class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" />
                <div class="py-4 text-sm font-medium text-white">{{ $page.props.flash.error }}</div>
              </div>
              <button type="button" class="group mr-2 p-2" @click="showFlash = false">
                <icon class="block h-3 w-3 fill-red-300 group-hover:fill-red-100" name="close" />
              </button>
            </div>
            <div
              v-if="$page.props.errors.length > 0 && showFlash"
              class="mb-8 flex max-w-lg items-center justify-between rounded bg-red-400"
            >
              <div class="flex items-center">
                <icon name="close-outline" class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" />
                <div class="py-4 text-sm font-medium text-white">
                  <span v-if="$page.props.errors.length === 1">{{ $t('There is one form error.') }}</span>
                  <span v-else>{{ $t('There are :length form errors', { length: $page.props.errors.length }) }}</span>
                </div>
              </div>
              <button type="button" class="group mr-2 p-2" @click="showFlash = false">
                <icon class="block h-3 w-3 fill-red-300 group-hover:fill-red-100" name="close" />
              </button>
            </div>
            <div v-if="$page.props.isImpersonating" class="no-print fixed bottom-0 left-0 mb-5 ml-8">
              <front-button-link :href="route('stop-impersonate')" as="button" type="button" class="btn-red">
                {{ $t('Stop Impersonating') }}
              </front-button-link>
            </div>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/Utils/Http'
import zendesk from '@/mixins/zendesk.js'

export default {
  mixins: [zendesk],
  props: {
    title: String,
  },
  data() {
    return {
      tenants: null,
      showFlash: true,
      open: false,
    }
  },
  computed: {
    pageTitle() {
      return this.title ? `${this.title} | ${this.$page.props.app.name}` : this.$page.props.app.name
    },
  },
  watch: {
    '$page.props.flash': {
      handler() {
        this.showFlash = true
      },
      deep: true,
    },
  },
  mounted() {
    // Hide Zendesk Chat Widget
    this.zE_hideWidget()
  },
  methods: {
    loadTenants() {
      if (!this.tenants) {
        return Http.get(this.route('tenants')).then((response) => {
          this.tenants = response.data.filter((tenant) => {
            return tenant.id !== this.$page.props.app.tenant.id
          })
        })
      }
    },
  },
}
</script>
